import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";

export const EmergingScienceWrapper = styled.div`
.mobile-banner {
  &.clinical-trials-banner{
    &:after{
      top:-5px;
    }
    .internal-banner{
      .internal-banner__contant{
        .o-container{
          padding: 0 2.7rem;
          @media ${device.ipadLandscapemin}{
            padding: 0 5rem;
          }
          @media ${device.desktopStart}{
            padding: 0 2rem;
          }
          h1{
            @media ${device.desktopStart}{
              padding: 0;
            }
          }
        }
      }
    }
  }
}
  .pagewrapper-emerging{
    &:before{
      @media ${device.ipadLandscapemin}{
        top:-55px;
        height: calc(100% - 485px);
      }
      @media ${device.desktopsignup}{
        top:41px;
        height: calc(100% - 630px) !important;
      }
    }
  }
`;

export const EmerFooterBlock = styled.div`
	.footer-block {
		&.footer-block-bg {
			background-color: transparent;
			padding-top: 0;
		}

		.footer-block--row {
			.footer-inner-block {
				&:before {
					background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
				}
        p{
          margin-bottom: 0;
        }
			}
		}
	}

	&.gen-footer-block {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;
			}
		}
	}

	&.imp-gens {
		.footer-block {
			&.footer-block-bg {
				background: transparent;
				padding-top: 0;

				.footer-inner-block {
					&:first-child {
						&:before {
							content: "";
							background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
							width: 100%;
							height: 16px;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 5px 5px 0 0;
						}
					}

					&:last-child {
						&:before {
							background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
						}
					}
				}
			}
		}
	}
`;

export const RepWrapper = styled.div`
	max-width: 1208px;
	margin: 0 auto;
	padding: 0 2.7rem;
  max-width: 718px;

  @media ${device.ipadLandscapemin} {
		padding: 0 2rem !important;
    max-width: 1208px;
	}

	.o-inner {
    padding: 0 100px;

		@media ${device.laptopMedium} {
			padding: 0
		}

		.emerging-description-paragraph {
			&.o-top-space {
        padding: 32px 0 70px;

				@media ${device.ipadLandscapemin} {
					padding: 40px 0 40px;
				}
			}
		}

		.description-para {
			.faq-question {
				max-width: 868px;
				margin: 48px auto 0;
				position: relative;

				@media ${device.laptopMedium} {
					margin: 0 auto;
					text-align: center;
				}

				img {
					position: absolute;
					left: 0;
					top: 0;

					@media ${device.laptopMedium} {
						position: unset;
						left: unset;
						top: unset;
						width: 33px;
						margin-bottom: 12px;
					}
				}

				.faq-answer {
					padding-left: 100px;

					@media ${device.laptopMedium} {
						padding-left: unset;
    				text-align: center;
					}

					p, span {
            font-family: "Gotham", Arial, Helvetica, sans-serif;
						font-weight: normal;
						padding-top: 0;
						font-size: 2.2rem;
						line-height: 3.2rem;
						color: #000000;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.2rem;
						}

						em {
							font-style: italic;
						}
					}
				}
			}
		}
	}
`;

export const InfoBoxWrapper = styled.div`
	&.infobox-text-reduce {
		margin: 10px 0 70px 0px;
	}

	&.o-column--full {
    width: 100%;
	}

	&.infobox-text {
    position: relative;

		&:before {
			content: "";
			background-color: #00FFD9;
			height: 170px;
			position: absolute;
			left: 0;
			top: 85px;
			width: 100%;

			@media ${device.tabletMax} {
				top: 140px;
			}

			@media ${device.laptopMedium} {
				height: 200px;
				top: 60px;
			}
		}

		.o-container {
			max-width: 1208px;
			margin: 0 auto;
			padding: 0 2rem;

			@media ${device.tabletMax} {
				padding: 0 2.7rem;
			}

			@media ${device.laptopMedium} {
				max-width: 718px;
			}
		}

		.info-box {
			display: flex;
			align-items: center;
			background-color: #300064;
			width: 100%;
			padding: 32px 0;
			margin: 0;
			text-align: center;
			position: relative;

			@media ${device.laptopMedium} {
				display: block;
			}
		}

		.step-info-box {
			flex-wrap: wrap;

			@media ${device.laptopMedium} {
				margin: 0 !important;
    		padding: 12px 27px 22px 27px !important;
			}

			.o-inner {
				padding: 0 90px;

				@media ${device.laptopMedium} {
					padding: 0;
				}
			}

			.o-inner-wrapper {
				&:first-child {
					p {
						@media ${device.laptopMedium} {
							padding: 0 0 28px 0 !important;
							margin-top: 10px;
						}
					}
				}
			}

			p {
				font-size: 2rem;
				line-height: 2.6rem;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				font-weight: 700;
				padding: 0;
    		text-align: center;
				color: #fff;
				margin-bottom: 0;

				@media ${device.laptopMedium} {
					display: inline-block;
					font-size: 1.8rem;
					line-height: 2.2rem !important;
				}

				strong {
					display: initial;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
					line-height: 3.8rem !important;
					color: #00FFD9;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem !important;
            font-family: "Gotham", Arial, Helvetica, sans-serif;
					}
				}
			}

			a {
				font-size: 2rem;
				line-height: 2.6rem;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				font-weight: 700;
				padding-top: 16px;
				text-align: center;
				padding: 15px 0 0;
				text-decoration: underline;
				text-underline-offset: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				position: relative;

				@media ${device.laptopMedium} {
					padding: 0;
					font-size: 1.8rem;
					line-height: 2.2rem !important;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
					display: block;
				}
			}
		}
	}
`;

export const CommonParagraphEmergingScience = styled.div`
	&.emerging-science-common-paragraph {
		@media ${device.laptopMedium} {
			margin-top: 100px;
		}

		.o-container {
			max-width: 1208px;
			margin: 0 auto;
			padding: 0 2rem;

			@media ${device.tabletMax} {
				padding: 0 2.7rem;
			}

			@media ${device.laptopMedium} {
				max-width: 718px;
			}

			.o-inner {
				padding: 0 100px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				h2 {
          font-family: "Gotham", Arial, Helvetica, sans-serif;
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #000000;
					text-align: center;

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3rem;
					}
				}

				.o-paragraph {
					p {
            font-family: "Gotham", Arial, Helvetica, sans-serif;
						padding-top: 40px;
						max-width: 768px;
						margin: auto;
						text-align: center;
						color: #000000;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							font-size: 1.6rem;
							line-height: 2rem !important;
							padding-top: 23px !important;
							padding-bottom: 20px;
						}
					}
				}
			}
		}
	}
`;

export const RightImagePoints = styled.div`
	&.right-image-points {
		&.emerging-science--full {
			display: flex;
			flex-wrap: wrap;
			padding: 40px 0 88px;

			@media ${device.laptopMedium} {
				padding: 24px 0 48px;
				padding-top: 0;
			}

			.left-description {
				padding-left: calc((100vw - 1168px) / 2);
				width: 66.6%;
				padding-right: 48px;
				float: left;

				@media ${device.laptopMedium} {
					width: 100%;
					padding-right: 20px;
					padding-left: 20px;
					margin-top: 0;
				}

				@media ${device.laptop} and ${device.midDesktopMax} {
					padding-right: 150px;
				}

				ul {
					margin-left: 100px;
					position: relative;

					@media ${device.laptopMedium} {
						margin-left: unset;
					}

					li {
            font-family: "Gotham", Arial, Helvetica, sans-serif;
						align-items: center;
						color: #000000;
						padding: 24px 0 24px 0px;
						display: flex;
						font-weight: 500;

						@media ${device.laptopMedium} {
							padding: 20px 0;
							font-size: 1.6rem;
							line-height: 2rem;
							text-align: center;
    					display: block;
						}

						&:first-child {
							padding-top: 0;
						}

						span {
							padding-top: 0;
							margin-right: 30px;

							@media ${device.laptopMedium} {
								position: unset;
								left: unset;
								margin-right: 0;
							}

							&:after {
								@media ${device.laptopMedium} {
									content: " ";
									white-space: pre;
									display: block;
								}
							}

							img {
								height: 80px;
								width: auto !important;
								max-width: 35px;

								@media ${device.laptopMedium} {
									padding-top: 0;
									padding-bottom: 0 !important;
									margin: auto;
									display: block;
								}
							}
						}

						.paragraph-wrap {
							p {
								margin: 0;
								font-size: 2rem;
    						line-height: 2.6rem;
								font-weight: 500;

								@media ${device.laptopMedium} {
									font-size: 1.6rem;
    							line-height: 2rem;
									text-align: center;
								}

								strong {
									font-weight: bolder;
								}
							}
						}
					}
				}
			}

			.right-img {
				position: absolute;
				right: 0;
				text-align: center;

				@media ${device.laptopMedium} {
					width: 100%;
					position: unset;
					margin-top: 20px;
				}

				img {
					max-width: 100%;
				}
			}
		}
	}
`;

export const WhenYoutest = styled.div`
	&.emerging-science-when-you-test {
		.o-inner {
			padding: 0 100px;

			@media ${device.laptopMedium} {
				padding: 0;
			}

			.specialist-infobox.info-box {
				margin-bottom: 120px;
				padding: 32px 80px;

				@media ${device.laptopMedium} {
					margin-bottom: 28px;
					padding: 24px 42px;
				}
			}

			.info-box {
				display: flex;
				align-items: center;
				background-color: #300064;
				padding: 27px 102px;
				position: relative;
				margin: auto;
				width: 100%;
				text-align: center;

				@media ${device.laptopMedium} {
					display: block;
				}

				img {
					position: absolute;
					left: 100px;
					max-width: 100%;

					@media ${device.laptopMedium} {
						display: block;
    				margin: 0 auto 10px;
						width: 45px;
						left: 0;
						right: 0;
						text-align: center;
						position: unset;
					}
          @media ${device.ipadLandscapemin}{
            top: 65px;
          }
          @media ${device.desktopStart}{
            top: 33px;
          }
				}

				.ask-specialist {
					p,h2 {
						padding: 0 0 0 120px;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						font-weight: 700;
						text-align: left;
						color: #fff;
						line-height: 3.2rem;
						font-size: 2.2rem;
						margin: 0;

						@media ${device.laptopMedium} {
							padding: 0;
              font-family: "Gotham", Arial, Helvetica, sans-serif;
							line-height: 2rem;
							font-size: 2rem;
							display: inline-block;
							text-align: center;
    					margin-top: 10px;
						}

						strong {
							color: #00FFD9;
							display: inline-block;
							font-size: 2.7rem;
    					padding-bottom: 12px;
							font-weight: 600;

							@media ${device.laptopMedium} {
								line-height: 2.2rem;
								padding-bottom: 32px;
								font-size: 2rem;
							}
						}
					}

					.ask-specialist-span {
						p {
							strong {
								padding: 0;
								font-family: 'Gotham-Book';
								font-weight: 700;
								text-align: left;
								color: #fff;
								line-height: 3.2rem;-
								font-size: 2.2rem;

								@media ${device.laptopMedium} {
                  font-family: "Gotham", Arial, Helvetica, sans-serif;
									font-size: 1.6rem;
									line-height: 2rem;
									font-size: 2rem;
									display: inline-block;
									text-align: center;
									margin-top: 10px;
									font-weight: 600;
								}
							}
						}
					}
				}

				.link-right-arrow {
					cursor: pointer;
					background-color: #fff;
					width: 40px;
					height: 40px;
					line-height: 46px;
					text-align: center;
					border-radius: 50%;
					margin: 5px 0 0 28px;

					@media ${device.laptopMedium} {
						display: flex;
						margin: 20px auto 0;
						justify-content: center;
						align-items: center;
						position: initial;
					}

					@media ${device.ipadLandscapemin} {
						width: auto;
						height: auto;
						line-height: unset;
					}

					&:hover {
						transform: scale(1.2);
						transition: ease 5ms;
					}

					.media--image {
						@media ${device.ipadLandscapemin} {
							width: 40px;
							padding-bottom: 0;
						}

						img {
							display: block;
							margin: 13px auto;
							max-width: 100%;
							position: static;
							left: 0;

							@media ${device.laptopMedium} {
								margin: 0;
								width: auto;
							}
						}
					}
				}
			}
		}
	}
`;

export const LeftImagePoints = styled.div`
	padding: 120px 0 94px;

	@media ${device.laptopMedium} {
		padding: 44px 0 21px;
		width: 100%;
	}

	&.genetic-left-img-points {
		@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
			padding-left: 5rem;
			padding-right: 5rem;
		}
	}

	h2 {
		font-size: 4.2rem;
    font-weight: 700;
    line-height: 5rem;
    color: #000000;
    text-align: center;
		width: 60%;
    margin: 0 auto 60px;

		@media ${device.laptopMedium} {
			width: 100%;
			padding: 0 30px;
			display: flex;
			font-size: 2.5rem;
    	line-height: 3rem;
			margin: 0 auto 20px;
		}
	}

	.listing-btm-section {
    display: flex;
    flex-wrap: wrap;

		@media ${device.laptopMedium} {
			display: block;
		}

		.left-img {
			width: 47.4%;
			max-width: 696px;
			margin: 0 auto;

			@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
				width: 45%;
			}

			@media ${device.desktopStart} {
				width: 45%;
				max-width: 696px;
			}

			@media ${device.laptopL} {
				width: 46%;
			}

			@media ${device.extraLargeDesktop} {
				width: 47.5%;
				max-width: unset;
				margin: unset;
			}

			@media ${device.laptopMedium} {
				width: 100%;
				margin: 0 auto 17px;
			}

			.gatsby-image-wrapper {
				>div {
					@media ${device.extraLargeDesktop} {
						max-width: 884px !important;
					}
				}

				img {
					@media ${device.extraLargeDesktop} {
						max-width: 884px;
						width: 884px;
					}
				}
			}
		}

		ul {
			&.right-description {
				width: 52%;
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding-left: 48px;
				padding-right: calc((100vw - 1168px) / 2);
				display: table;

				@media ${device.laptopMedium} {
					width: 100%;
					padding: 0 28px;
				}

				li {
					padding: 0px 0 24px 26px;
    			line-height: 3.1rem;
					display: inline-block;
    			position: relative;
					color: #000000;
					font-size: 2.2rem;
    			font-weight: 500;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						padding: 0px 0 15px 24px;
					}

					&:before {
						top: 15px;
    				left: 1px;
						content: "";
						position: absolute;
						background-color: #8000BE;
						width: 8px;
						height: 8px;
						transform: translateY(-50%);
    				margin: auto;
					}
				}
			}
		}
	}
`;
