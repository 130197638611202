export const patientES: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO",
  footerMenu: "Footer Navigation Menu Consumer GCSO",
  banner: "Banner - HTML - Emerging Science - DTC - Canada",
  footer: "Footer DTC HTML Canada",
  exitPopup: "Retina Canada Exit Popup HTML",
  hcpSwitcher: "HCP Switcher Modal Pop Up",
  topNav: "Top Navigation Menu Retina Canada HTML DTC",
  faqSection: "Emerging Science FAQ Section HTML",
  learnMore: "Emerging Science Learn More HTML",
  whenYouTest: "Emerging Science When You Test HTML",
  footerCallout: "Emerging Science Navigational Callout HTML",
  siteLogo: "Site logos DTC",
  video1GTMLabel: "Genes 101",
  video2GTMLabel:  "Inheritance 101",
  videoTopPara: "Community Resources Video Thumbnail Heading HTML",
  crTitleCard: "Community Resources Title Card",
  titleCard: "Community Resources - Education, Support, & Community (Updated)",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/emerging-science",
      "fr": "/fr/emerging-science"
    }
  },
  backToTopGTM: "Back to top - emerging science",
  footerClassName: "emerging-science-footer"
}
