import React from 'react'
import { graphql } from 'gatsby'
import { Layout, HTMLRenderer, PageWrapper } from '@retina-packages/retina-theme-bootstrap'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { FooterCalloutBlock } from '@retina-packages/retina-theme-bootstrap/packages/containers/FooterNavigationCallOutContainer/style';
import { patientES } from './constants';
import { EmerFooterBlock, EmergingScienceWrapper, InfoBoxWrapper, RepWrapper, WhenYoutest } from './styles';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import retinaConfig from '../../../utils/retinaConfigs';
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';

const EmergingScienceTemplate = (props: Props) => {
  /**
   * Global section data building
   */
  const pgProp: PageQueryResult = props.data
  const blocksCO = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsCS = props.pageContext
  const htmlStaticFilesCS = {
    nonSVGImages: pgContextsCS.nonSVGImages,
    svgMediaImages: pgContextsCS.svgMediaImages,
    allMediaDocument: pgContextsCS.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientES.mainMenu, siteFooterMenu: patientES.patFooterMenu });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientES.footer
  const exitPopupTitle = patientES.exitPopup
  const hcpSwitcherTitle = patientES.hcpSwitcher
  const topNavTitle = patientES.topNav

  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string
  } = reusableDataBuilders({blocks: blocksCO, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const siteLogos = deriveMultiLogo({blocks: blocksCO, title: patientES.siteLogo})
  const bannerHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientES.banner})

  /**
   * Page specific data building
   */
  const faqSectionHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientES.faqSection})
  const learnMoreHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientES.learnMore})
  const whenYouTestHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientES.whenYouTest})
  const footerCalloutHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientES.footerCallout})

  return (
    <EmergingScienceWrapper>
      {props.pageContext !== null && props.pageContext.metaInfo !== null && (
        <MetaTagContainer metaData={props.pageContext.metaInfo} />
      )}
      <Layout
          title={"siteTitle"}
          location={props.location}
          data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          exitPopData={exitData}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          backToTopContent={retinaConfig.backToTopContent}
          gtmBackToTopLabel={patientES.backToTopGTM}
          staticMediaFiles={htmlStaticFilesCS}
          hcpValidate={retinaConfig.hcpValidate}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          preIndexUrl={retinaConfig.preIndexUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={patientES.footerClassName}
          languageConfig={{... retinaConfig.langConfig, ...patientES.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
      >
        <PageWrapper className='pagewrapper pagewrapper-emerging'>
          {/* Banner section */}
          <MobileBanner className="mobile-banner clinical-trials-banner">
            <HTMLRenderer data={htmlStaticFilesCS} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true}/>
          </MobileBanner>
          <RepWrapper className='o-container'>
            <HTMLRenderer data={htmlStaticFilesCS} html={faqSectionHTML} tagName='div' className='o-inner' />
          </RepWrapper>
          <InfoBoxWrapper className='infobox-text o-column--full infobox-text-reduce emerging-science-learn-more'>
            <HTMLRenderer data={htmlStaticFilesCS} html={learnMoreHTML} tagName='div' className='o-container' />
          </InfoBoxWrapper>
          <WhenYoutest className='o-container emerging-science-when-you-test'>
            <HTMLRenderer data={htmlStaticFilesCS} html={whenYouTestHTML} tagName='div' className='o-inner' />
          </WhenYoutest>
          <EmerFooterBlock className='emr-footer-block'>
          <FooterCalloutBlock className='o-container padding-cards-fix'>
            <HTMLRenderer data={htmlStaticFilesCS} html={footerCalloutHTML} tagName='div' className='footer-block footer-block-bg' />
          </FooterCalloutBlock>
          </EmerFooterBlock>
        </PageWrapper>
      </Layout>
    </EmergingScienceWrapper>
  )
}

export default EmergingScienceTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... EmergingSciencePageQuery
      }
    }
  }
`
